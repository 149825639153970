<template>
  <div class="modal-reset-inner">
    <mkp-text-field class="text-reset">{{
      $t("forms.resetPassword.form.text")
    }}</mkp-text-field>

    <form novalidate @submit.prevent="submit">
      <mkp-email-component
        v-model="form.email"
        :v="$v.form.email"
        placeholder="forms.resetPassword.form.email.placeholder"
      />

      <mkp-button class="new-pass-btn" @click="submit">
        {{ $t("forms.resetPassword.form.button") }}
      </mkp-button>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import MkpEmailComponent from "../../inputsForm/mkp-email-component";
import MkpTextField from "../../../../simple/text-field/mkp-text-field";
import MkpButton from "../../../../simple/button/mkp-button";
import "../mkp-reset-password.scss";

export default {
  name: "MkpResetPasswordForm",
  components: { MkpButton, MkpTextField, MkpEmailComponent },
  data: () => {
    return {
      form: {
        email: null
      }
    };
  },
  validations: {
    form: {
      email: {
        required
      }
    }
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return;
      // to form submit after this
      this.$emit("Resetsubmit", this.form);
    }
  }
};
</script>

<style scoped></style>
